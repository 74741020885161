<template>
  <van-uploader v-model="fileList" :after-read="afterRead" :class="{ rectangle: rectangleStyle }" v-bind="$attrs" v-on="$listeners" @delete="removeFile">
    <van-button v-if="uploadBtnText" icon="plus" size="small" type="primary">
      {{ uploadBtnText }}
    </van-button>
  </van-uploader>
</template>

<script>
import { Uploader, Button } from 'vant'
export default {
  name: 'UploadFile',
  components: {
    [Uploader.name]: Uploader,
    [Button.name]: Button
  },
  model: {
    prop: 'files',
    event: 'change'
  },
  props: {
    // 文件列表
    files: {
      type: Array,
      default () {
        return []
      }
    },
    // 长方形区域模式 传入 previewSize 后无效
    rectangleStyle: {
      type: Boolean,
      default: false
    },
    // 上传API 方法
    uploadApi: {
      type: Function,
      default: undefined
    },
    // 同时上传的query参数
    query: {
      type: Object,
      default () {
        return {}
      }
    },
    // 上传按钮文字 传入则显示上传按钮
    uploadBtnText: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      fileList: []
    }
  },
  watch: {
    files: {
      handler: function (newValue, oldValue) {
        const arr = []
        if (newValue) {
          newValue.forEach(item => {
            arr.push({
              url: item.fileUrl,
              response: item
            })
          })
        }
        this.fileList = arr
      },
      deep: true
    }
  },
  methods: {
    afterRead (fileData) {
      const formData = new FormData()
      formData.append('file', fileData.file)
      fileData.status = 'uploading'
      fileData.message = '上传中'
      const api = this.uploadApi || this.api.base.file.upload
      api(formData, this.query).then(result => {
        fileData.status = 'done'
        fileData.message = '上传完成'
        fileData.response = result.data.value
        this.change()
        this.$emit('fileUploadSuccess', result.data.value, fileData, this.fileList)
      }).catch(e => {
        fileData.status = 'failed'
        fileData.message = '上传失败'
        this.$emit('fileUploadError', e)
      })
    },
    removeFile (fileData, detail) {
      this.$emit('delete', fileData, detail)
      this.change()
    },
    change () {
      const arr = []
      this.fileList.forEach(item => {
        if (item.response) {
          arr.push(item.response)
        }
      })
      this.$emit('change', arr)
    }
  }
}
</script>

<style lang="less" scoped>
  @width: 4rem;
  .rectangle /deep/ .van-uploader__preview-image,
  .rectangle /deep/ .van-uploader__upload {
    width: @width;
    height: @width /4*3
  }
</style>
