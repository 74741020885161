var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "van-uploader",
    _vm._g(
      _vm._b(
        {
          class: { rectangle: _vm.rectangleStyle },
          attrs: { "after-read": _vm.afterRead },
          on: { delete: _vm.removeFile },
          model: {
            value: _vm.fileList,
            callback: function($$v) {
              _vm.fileList = $$v
            },
            expression: "fileList"
          }
        },
        "van-uploader",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _vm.uploadBtnText
        ? _c(
            "van-button",
            { attrs: { icon: "plus", size: "small", type: "primary" } },
            [_vm._v(" " + _vm._s(_vm.uploadBtnText) + " ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }